import React from 'react'
import { Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import mhg from "../Assets/mhg.webp"

const Loading = ({ message }) => {
  let params = useParams()
  return (
    <div className="h111">
      <div className='text-center h222'>
        <Image src={mhg} fluid style={{ height: "96px", margin: "8px" }} />
        <ScaleLoader /><br />{message}</div>
    </div>

  )
}

export default Loading