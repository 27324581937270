import React, { Suspense } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Styles/Style.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import H404 from './Components/H404'
import Loading from './Components/Loading'

const ProfileView = React.lazy(() => import('./Components/ProfileView'))
const CompanyView = React.lazy(() => import('./Components/CompanyView'))
const Admin = React.lazy(() => import('./Components/Admin'))
const MenuView = React.lazy(() => import('./Components/Menu/MenuView'))


function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to="/mansourmhg" replace="true" />} />
      <Route path="/:userID/menu" element={<Suspense fallback={<Loading />}><MenuView /></Suspense> } />
      <Route path="404" element={ <H404 /> } />
      <Route path=":userID" element={<Suspense fallback={<Loading />}><ProfileView /></Suspense> } />
      <Route path="c/:companyID/:userID" element={<Suspense fallback={<Loading />}><ProfileView /></Suspense> } />
      <Route path="c/:companyID" element={<Suspense fallback={<Loading />}><CompanyView /></Suspense> } />
      <Route path="*" element={  <H404 /> } />
      <Route path="user" element={<Suspense fallback={<Loading />}><Admin /></Suspense>} />
    </Routes>
  );
}


export default App
