// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2wMlN6DT74hEoh1ka-bNu2ezpXMjcKig",
  authDomain: "tappo-standalone-one-379a0.firebaseapp.com",
  projectId: "tappo-standalone-one-379a0",
  storageBucket: "tappo-standalone-one-379a0.appspot.com",
  messagingSenderId: "967932468043",
  appId: "1:967932468043:web:6e60ee951999dea275e6ce",
  measurementId: "G-2RE4CTDE0N"
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const authentication = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const profileRef = collection(db, "profileData")
export const usersRef = collection(db, "users")
export const aliasRef = collection(db, "alias")
export const companyRef = collection(db, "companies")
export const menuRef = collection(db, "menuData")
export const leadsRef = collection(db, "leads")